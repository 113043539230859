<template>
    <div>
        <Button
                icon="pi pi-file-export"
                class="p-button-sm p-button-rounded p-button-info mr-2"
                @click="toOpenModal"
              />
              <v-dialog
              v-model="dialog"
              max-width="600px"
              :persistent="formLoading"
              transition
            >
              <v-card
              >
                <v-card-title class="font-weight-bold justify-center">
                    {{ $t('export_customer_io') }}
                </v-card-title>
                <ValidationObserver ref="observer" v-slot="{ invalid, validate }">
                    <v-card-text class="pb-0 pt-1 text-left space-y-2">
                        <Message v-if="traitsError" severity="error" :closable="false">
      
                            {{ $t('select_at_least_one_field') }}
                        </Message>
                      <div class="text-right">
                          <!-- <Button
                          :label="$t('add_field')"
                      icon="pi pi-plus"
                      class="p-button-sm p-button-success "
                      @click="toAddField"
                    /> -->
                    <Checkbox 
                      id="selectAll" 
                      v-model="isAllSelected" 
                      :binary="true" 
                      @change="toggleSelectAll"
                    />
                    <label for="selectAll" class="ml-2 font-weight-bold">{{ $t('select_all') }}</label>
                      </div>
                      <div class="wrapper-fields">
                          <div v-for="(trait, traitIndex) in traitsToExportData" :key="traitIndex" class="field-checkbox">
                            <Checkbox :id="trait.value" 
                            name="category" 
                            :value="trait.isAdd" 
                            v-model="trait.isAdd" 
                            :binary="true"
                            />
                            <label :for="trait.value" class="ml-2 font-weight-bold">{{$t(trait.value)}}</label>
                        </div>
                      </div>
                      <!-- <Card v-for="(trait, traitIndex) in traitsToExportData" :key="traitIndex" class="card-custom">
                          <template #title>
                              <div class="text-right" v-if="traitIndex > 0">
                                  <Button
                                  icon="pi pi-minus"
                                  class="p-button-sm p-button-danger"
                                  @click="toDeleteField(traitIndex)"
                                  />
                                </div>
                            </template>
                            <template #content>
                                <h4 class="c-text white-space d-flex align-center">
                                    {{ $t('field') }}
                                    <span class="c-red">*</span>
                                    :
                                </h4>
                                <v-menu v-model="trait.menu" 
                                offset-y
                                :close-on-content-click="false" 
                                nudge-left="0" 
                                nudge-right="0"
                                >
                                <template v-slot:activator="{ on }">
                                    <div class="" v-on="on">
                                        <ValidationProvider
                                        name="value"
                                        rules="required"
                                        v-slot="{ errors, validate, validated }"
                                        class="d-flex flex-column"
                                        >
                                        <span class="p-input-icon-right"  >
                                            <i class="pi pi-angle-down" />
                                <InputText 
                                :value="$t(trait.value)"
                                class="p-inputtext-sm fw" 
                                :placeholder="$t('field')">
                                </InputText>
                                </span>
                                <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                            </ValidationProvider>
                                </div>
                                
                          </template>
                            <Listbox v-model="trait.value" :options="traitsOptions"
                            @change="trait.menu = false"
                            :filter="true"
                            class="custom-dropdown"
                            :emptyFilterMessage="$t('no_data')"
                            style="width:auto">
                            <template #option="slotProps">
                                <div>
                                    <div>{{$t(slotProps.option)}}</div>
                                </div>
                                </template>
                            </Listbox>
              
                        </v-menu>
                    </template>
                    </Card> -->
                </v-card-text>
                <v-card-actions>
                    <Button 
                    @click="handleSubmit(validate)"
                    :disabled="formLoading"
                    :loading="formLoading"
                       :label="$t('export')"
                        icon="pi pi-file-export" 
                        class="p-button-sm p-button-success fw" />
                </v-card-actions>
              </ValidationObserver>
              </v-card>
            </v-dialog>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

    export default {
        name: "ModalExportSegment",
        props:{
            segment: {
                type: Object,
            }
        },
        data() {
            return {
                dialog: false,
                formLoading: false,
                traitsError: false,
                isAllSelected: true,
                traitsToExportData: [
                    {
                        isAdd: true,
                        value: 'email'
                    }
                ],
            }
        },
        computed:{
            ...mapGetters({
                traitsOptions: 'segments/getTraitsToExport',
            })
        },
        watch: {
    traitsOptions: {
        immediate: true, 
        handler(newOptions) {
            if (Array.isArray(newOptions) && newOptions.length) {
                this.traitsToExportData = newOptions.map(trait => ({
                    isAdd: true,
                    value: trait
                }));
            }
        }
    },
    traitsToExportData: {
      handler() {
        this.updateSelectAllState();
      },
      deep: true 
    }
},
        methods: {
            toggleSelectAll() {
      this.traitsToExportData.forEach(trait => {
        trait.isAdd = this.isAllSelected;
      });
    },
    updateSelectAllState() {
      this.isAllSelected = this.traitsToExportData.every(trait => trait.isAdd);
    },
            toOpenModal(){
                this.dialog = true
            },
            toAddField(){
                this.traitsToExportData.push({
                    menu: false,
                    value: null
                })
            },
            toDeleteField(index){
                this.traitsToExportData.splice(index, 1)
            },
            handleSubmit(validate) {
                validate().then(valid => {
                    if (valid) {
                        this.traitsError = false;
                        if (this.traitsToExportData.length === 0) {
                            this.traitsError = true;
                            this.toAddField();
                            return
                        }
                      this.exportSegment();
                    }
                })
            },
            async exportSegment(){
                this.formLoading = true
                try {
                    const traits_to_export = {
                        traits_to_export: [
        ...this.traitsToExportData
            .filter(trait => trait.isAdd) 
            .map(trait => trait.value)    
                ]}
                    // console.log('traits_to_export', traits_to_export);
                    
                    await this.$store.dispatch("segments/awaitExportTraits", {segment: this.segment.id, traits: traits_to_export})
                    this.$toast.add({ severity: 'success', summary: this.$t('export_success'), 
                    life: 4000 })
                    this.dialog = false
                } catch (error) {
                    this.$toast.add({ severity: 'error', summary: this.$t('just_error'), 
                    detail: error, 
                    life: 4000 })
                } finally{
                    this.formLoading = false
                }
            }
    },
    }
</script>

<style lang="scss" scoped>
.wrapper-fields{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
}
</style>